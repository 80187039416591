<template lang="pug">
ui-dialog(
  modal
  closable
  width="520px"
  :visible-prop="show"
  @content:hide="hide"
)
  template(#header) {{ mode === 'lock' ? 'Архивировать' : 'Разархивировать' }} акцию "{{ promo.serviceTitle }}"?
  template(#content)
    .error-message(v-if="error") При смене статуса акции произошла ошибка, попробуйте позднее.
  template(#actions)
    ui-button(type="secondary" @click="hide") Отменить
    ui-button.delete-button(:loader="loader" @click="saveChanges") {{ mode === 'lock' ? 'Архивировать' : 'Разархивировать' }}
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useApi } from "@/use/api/useApi";

import type { PropType } from "vue";
import type { PromoI } from "~/use/other/usePromo";

import UiButton from "@/components/ui/button/UiButton.vue";
import UiDialog from "@/components/ui/dialog/UiDialog.vue";

export default defineComponent({
  name: "PromoStatusDialog",
  components: {
    UiButton,
    UiDialog,
  },
  emits: [
    'success',
    'update:show',
  ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    promo: {
      type: Object as PropType<PromoI>,
      default: () => ({})
    },
    mode: {
      type: String as PropType<'lock'|'unlock'>,
      default: 'lock',
    },
  },
  setup(props, context) {

    const loader = ref(false);
    const error = ref(false);

    function hide() {
      error.value = false
      context.emit('update:show', false)
    }

    function saveChanges() {
      loader.value = true;
      error.value = false;

      useApi().promo.patchPromo(props.promo?.id, { isActual: props.mode !== 'lock' })
        .then((response: any) => {
          context.emit('success', response)
          hide()
        })
        .catch(() => error.value = true)
        .finally(() => loader.value = false)
    }

    return {
      loader,
      error,
      hide,
      saveChanges,
    }
  }
})
</script>

<style scoped lang="scss">
.error-message {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-red-color);
}

.delete-button {
  color: var(--main-red-color);
  background-color: #ffecf0;
  border-color: var(--main-red-color);

  &:hover {
    color: white;
    border-color: var(--main-red-color) !important;
    background-color: var(--main-red-color) !important;
  }
}
</style>
