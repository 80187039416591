<template lang="pug">
ui-loader(v-if="isPromoFetching" label="Загружаем информацию")
.fetch-error(v-else-if="isPromoFetchingError") Не удалось получить информацию об акции, пожалуйста, вернитесь назад
.promo-dashboard(v-else)
  .promo-details
    .promo-label
      .label Название акции
      .description Для внутреннего использования
    .settings
      form-input(v-model="promo.serviceTitle" label="Название")
  .promo-details
    .promo-label
      .label Главный экран
      .description Детали акции для отображения на главном экране
      article-image(:src="images[0].src" :alt="images[0].alt" width="280px" hide-label show-cursor @click="openViewer(images, 0)")
    .settings
      form-textarea(v-model="promo.landingPromoTitle" label="Заголовок" :min-height="36")
      form-textarea(v-model="promo.landingPromoAgreement" label="Сноска")
  .promo-details
    .promo-label
      .label Информационная строка
      .description Детали акции для отображения над тарифами
      article-image(:src="images[1].src" :alt="images[1].alt" width="280px" hide-label show-cursor @click="openViewer(images, 1)")
    .settings
      form-textarea(v-model="promo.promoTitle" label="Заголовок" :min-height="36")
      form-textarea(v-model="promo.promoDescription" label="Описание")
      form-textarea(v-model="promo.promoAgreement" label="Подробности")
  .promo-details
    .promo-label
      .label Цены
      .description Прочие настройки
      article-image(:src="images[2].src" :alt="images[2].alt" width="280px" hide-label show-cursor @click="openViewer(images, 2)")
      span.description-rules Для того, чтобы указать цену по акции, выберите нужный тариф и длительность, затем нажмите "Указать правило". Можно указать фиксированную цену (нажать на знак ₽) или указать размер скидки в процентах от 0 до 100 (нажать на знак %). При указании скидки в процентах, в поле ввода автоматически будет высчитана стоимость тарифа со скидкой, пожалуйста, проверьте корректность расчета. При необходимости задайте цену в рублях.
    .manual-prices
      .tariff(v-for="t of defaultPrices" :key="`tariff-row-${t.tariffId}`")
        .tariff-label Тариф "{{ t.tariffTitle }}"
        .tariff-setter
          manual-price-setter(
            v-for="p of periods"
            :key="`setter-${t.tariffId}-${p.duration}`"
            v-model="promo.rules[`${t.tariffId}-${p.duration}`]"
            :period="p.duration"
            :price="getPrice(t.cost, p)"
            :base-cost="t.cost"
            v-model:selectedRules="selectedRules"
            :rule-key="`${t.tariffId}-${p.duration}`"
          )
      .tariff
        span
        .rules-setter
          .all-setter
            ui-checkbox(v-model="selectAllGetter" id="selectAllPromoRules" label="Выбрать все" :indeterminate="isIndeterminate && !selectAllGetter")
            span.action(v-if="selectedRules.length" @click="showRulesSetter") + Указать скидку для выбранных элементов
          .rules(v-if="active && selectedRules.length")
            form-input(v-model="discount" label="Размер скидки в процентах (1-100)")
            ui-button.fit-height(type="secondary" @click="setRules") Добавить
            ui-button.fit-height(type="secondary" @click="cancel") Отменить
  .promo-details
    .promo-label
      .label Даты
    .flex-column
      .flex-row
        date-picker.picker(v-model="promo.dateStart" label="Дата начала акции")
        date-picker.picker(v-model="promo.dateFinish" label="Дата окончания акции")
      radio-group.row3fr(v-model="promo.periodType" :options="promoPeriodTypes")
  .promo-details
    .promo-label
      .label Видимость акции
    .settings
      radio-group.row2fr(v-model="newUsersOnlyComp" :options="options" id="promo-user-mode")
  .promo-details
    .promo-label
      .label Сохранение данных
    .flex-column
      .promo-save
        ui-checkbox(v-model="promo.isActual" label="Акция актуальна" id="public-the-promo" message="Оставьте поле пустым, если акция находится в архиве")
        ui-button(:loader="isPromoSaving" @click="savePromo") Сохранить
      span.error-message(v-if="isPromoSavingError") Проверьте, что поля заполнены корректно. Должен быть заполнен хотя бы один заголовок, а так же указана дата окончания акции.
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useApi } from "@/use/api/useApi";
import { getTariffCost } from "~/utils/tariffs/priceGenerator";
import { useImageViewer } from "~/use/other/useImageViewer";

import FormInput from "~/components/ui/form/input/FormInput.vue";
import FormTextarea from "~/components/ui/form/input/FormTextarea.vue";
import RadioGroup from "~/components/ui/radio/RadioGroup.vue";
import ManualPriceSetter from "~/components/pages/admin/promo/ManualPriceSetter.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import DatePicker from "~/components/ui/picker/DatePicker.vue";
import UiCheckbox from "~/components/ui/checkbox/UiCheckbox.vue";
import ArticleImage from "~/components/pages/training/ArticleImage.vue";
import UiLoader from "~/components/ui/loader/UiLoader.vue";

import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import { type PromoI, usePromo } from "~/use/other/usePromo";
import { type TariffI, type TariffPeriodI, TariffsListConst, TariffsPeriodConst } from "~/const/tariffs";

function prepareRules(r: any) {
  let res : any[] = []
  Object.keys(r).forEach(key => {
    if (r[key]?.value) res.push({
      tariff: `/api/tariffs/${key.split('-')[0]}`,
      tariffDuration: Number(key.split('-')[1]),
      isRubles: r[key].isRubles,
      value: Number(r[key].value),
    })
  })
  return res;
}

export default defineComponent({
  name: "PromoEditor",
  components: {
    UiLoader,
    ArticleImage,
    FormInput,
    FormTextarea,
    RadioGroup,
    UiButton,
    UiCheckbox,
    DatePicker,
    ManualPriceSetter,
  },
  props: {
    promoId: {
      type: Number,
      default: 0,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'success',
    'update:modelValue',
  ],
  setup(props, context) {

    const { showError } = useNotificationsStore();
    const { promoPeriodTypes, validateResponse, getDefaultPromoData } = usePromo();

    const promo = ref<PromoI>(getDefaultPromoData());

    const isPromoSaving = ref<boolean>(false);                   // сохранение акции
    const isPromoFetching = ref<boolean>(false);                 // получение акции
    const isPromoSavingError = ref<boolean>(false);              // ошибка при сохранении акции
    const isPromoFetchingError = ref<boolean>(false);            // ошибка при получении акции

    /** для поля isNewUsersOnly */
    const options = [
      { id: 1, title: 'Акция доступна только новым пользователям', },
      { id: 2, title: 'Акция доступна всем пользователям', },
    ]

    const newUsersOnlyComp = computed({
      get: () => promo.value?.isNewUsersOnly ? 1 : 2,
      set(value: number) {
        if (promo.value) promo.value.isNewUsersOnly = value === 1
      }
    })

    /** выбор нескольких тарифов для задания общего правила */
    const selectedRules = ref<string[]>([]);

    const active = ref(false)
    const discount = ref(0)

    const images = [
      { src: 'promos/main-screen.png', alt: 'Главный экран' },
      { src: 'promos/promo-screen.png', alt: 'Информационная строка' },
      { src: 'promos/promo-rules.png', alt: 'Видимость акции' },
    ]

    const { openViewer } = useImageViewer();

    function showRulesSetter() {
      if (selectedRules.value.length) active.value = true;
      else showError('Выберите нужные тарифы')
    }

    function setRules() {
      if (Number(discount.value) && !!selectedRules.value.length) {
        selectedRules.value.forEach(rule => {
          promo.value.rules[rule] = { value: discount.value, isRubles: false }
        })

        cancel()
      }
    }

    function cancel() {
      selectedRules.value = []
      active.value = false
    }

    const periods = TariffsPeriodConst
    const defaultPrices = TariffsListConst.map(e => ({ tariffId: e.id, cost: e.cost, tariffTitle: e.title }))

    /** список ключей "номер тарифа - длительность" ['1-1', '1-6', '1-12', '2-1', '2-6', '2-12', '4-1', '4-6', '4-12'] */
    const allIds = computed<string[]>(() => {
      return TariffsListConst.reduce((res: any, item: TariffI) => {
        TariffsPeriodConst.forEach((period: any) => res.push(`${item?.id}-${period.duration}`))
        return res;
      }, []);
    })

    const selectAllGetter = computed({
      get: () => allIds.value.every(id => selectedRules.value?.includes(id)),
      set(value: boolean) {
        selectedRules.value = value ? allIds.value : []
      }
    })

    const isIndeterminate = computed(() => allIds.value.some(id => selectedRules.value?.includes(id)))

    function getPrice(baseCost: number, period: TariffPeriodI) {
      return getTariffCost(baseCost, period.duration, period.discount)
    }

    function isPromoCorrect() {
      return promo.value?.dateFinish && (promo.value?.landingPromoTitle || promo.value?.promoTitle)
    }

    /** получение информации об акции */
    function fetchPromo() {
      if (props.isCreate || !props.promoId) {
        promo.value = getDefaultPromoData()
        return
      }

      isPromoFetching.value = true
      isPromoFetchingError.value = false

      useApi().promo.fetchPromoById(props.promoId)
        .then((response) => promo.value = validateResponse(response))
        .catch(() => isPromoFetchingError.value = true)
        .finally(() => isPromoFetching.value = false)
    }

    /** сохранение изменений */
    function savePromo() {
      isPromoSavingError.value = false

      if (!isPromoCorrect()) isPromoSavingError.value = true
      else {

        isPromoSaving.value = true

        const payload: any = {
          ...promo.value,
          rules: prepareRules(promo.value.rules),
          periodType: `/api/promo-period-types/${ promo.value.periodType }`,
          id: undefined,
          dateCreate: undefined,
        };

        (props.isCreate
          ? useApi().promo.postPromo(payload)
          : useApi().promo.patchPromo(promo.value?.id, payload))
            .then((response) => context.emit('success', response))
            .catch(() => showError(props.isCreate ? 'Не удалось создать акцию' : 'Не удалось обновить акцию'))
            .finally(() => isPromoSaving.value = false)
      }
    }

    /** ЗАПРАШИВАЕМ ИНФОРМАЦИЮ */
    fetchPromo()

    return {
      promo,
      isPromoSaving,
      isPromoSavingError,
      isPromoFetching,
      isPromoFetchingError,
      promoPeriodTypes,
      periods,
      options,
      active,
      discount,
      selectedRules,
      selectAllGetter,
      isIndeterminate,
      images,
      openViewer,
      showRulesSetter,
      setRules,
      getPrice,
      savePromo,
      cancel,
      defaultPrices,
      newUsersOnlyComp,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.error-message {
  @include error-message;
}

.fetch-error {
  font-size: 13px;
  color: var(--main-red-color);

  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
}

.promo-dashboard {
  display: flex;
  flex-flow: column;
  gap: 16px;

  .promo-details {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    gap: 40px;

    &:not(:last-child) {
      padding-bottom: 16px;
      border-bottom: 1px solid #e1e2e6;
    }

    .promo-label {
      display: flex;
      flex-flow: column;
      gap: 8px;

      .label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
      }

      .description {
        font-size: 12px;
        line-height: 18px;
        color: var(--secondary-text-color);
      }

      .description-rules {
        font-size: 12px;
        line-height: 18px;
        color: var(--default-blue-color);
      }
    }

    .settings {
      display: flex;
      flex-flow: column;
      gap: 16px;
    }

    .numeric-row {
      display: flex;
      flex-flow: row;
      gap: 12px;

      span {
        font-size: 11px;
        line-height: 17px;
      }
    }
  }

  .row2fr {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .row3fr {
    display: flex;
    flex-flow: row;
    gap: 16px;
  }

  .all-setter {
    display: grid;
    grid-template-columns: 180px 1fr;
    gap: 8px;
  }

  .manual-prices {
    display: flex;
    flex-flow: column;
    gap: 24px;
  }

  .tariff {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 16px;

    .tariff-label {
      font-size: 14px;
      line-height: 32px;
      font-weight: 600;
    }

    .tariff-setter {
      display: flex;
      flex-flow: column;
      gap: 8px;
    }
  }

  .flex-row {
    display: flex;
    flex-flow: row;
    gap: 16px;
    align-items: center;
  }

  .picker {
    width: 200px;
  }

  .flex-column {
    display: flex;
    flex-flow: column;
    gap: 16px;
  }

  .promo-save {
    display: flex;
    flex-flow: row;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
  }

  .action {
    color: var(--default-blue-color);
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
    font-size: 14px;
  }

  .rules-setter {
    display: flex;
    flex-flow: column;
    gap: 12px;
  }

  .rules {
    display: flex;
    flex-flow: row;
    gap: 8px;
    align-items: end;
  }

  .fit-height {
    height: fit-content;
  }
}
</style>
