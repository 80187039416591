<template lang="pug">
#admin-panel
  h1.main-page-title Панель администратора
  ui-tabs(:model-value="routeTab" :tabs="tabs" @update:modelValue="setTab")
  companies-list(v-if="routeTab === AdminTabs.Clients")
  managers-list(v-if="routeTab === AdminTabs.Managers")
  promo-list(v-if="routeTab === AdminTabs.Promos")
  campaign-list(v-if="routeTab === AdminTabs.Campaigns")
  //registration-dashboard
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { storeToRefs } from "pinia";
import { useRouteQuery } from "@vueuse/router";

import UiTabs from "~/components/ui/tab/UiTabs.vue";
import ManagersList from "@/components/pages/admin/managers/ManagersList.vue";
import CompaniesList from "@/components/pages/admin/managers/CompaniesList.vue";
import PromoList from "~/components/pages/admin/promo/PromoList.vue";
import CampaignList from "~/components/pages/admin/campaign/CampaignList.vue";
import RegistrationDashboard from "@/components/pages/admin/managers/RegistrationDashboard.vue";

import { AdminTabs } from "~/router/tabs";
import useTmManagerStore from "~/stores/tenmonManager/useTmManagerStore";

export default defineComponent({
  name: "Index",
  components: {
    UiTabs,
    CompaniesList,
    ManagersList,
    PromoList,
    CampaignList,
    RegistrationDashboard,
  },
  setup() {

    const managerStore = useTmManagerStore();
    const { adminPageMode } = storeToRefs(managerStore);

    const routeTab = useRouteQuery('tab', AdminTabs.Clients, { mode: 'push' });

    const tabs = computed(() => [
      { name: 'Список клиентов', key: AdminTabs.Clients },
      { name: 'Список менеджеров', key: AdminTabs.Managers },
      { name: 'Список акций', key: AdminTabs.Promos },
      { name: 'Рекламные кампании', key: AdminTabs.Campaigns },
    ].filter(Boolean))

    function setTab(t: string) {
      routeTab.value = t
      adminPageMode.value = t
    }

    onBeforeMount(() => {
      if (routeTab.value !== adminPageMode.value) routeTab.value = adminPageMode.value
    })

    return {
      tabs,
      routeTab,
      setTab,
      AdminTabs,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#admin-panel {
  @include page-view;

  display: flex;
  flex-flow: column;
  gap: 24px;
}
</style>
