<template lang="pug">
.promo-list
  alert-message(v-if="showPromoWarning" type="error" label="Несколько акций запущено одновременно, пожалуйста, отключите все, кроме одной")
  settings-panel.info-panel
    template(#header) Справка
    .info__status
      .info-row
        ui-tag.fit-height(size="small" name="Активно" type="success")
        span Текущая акция - активная акция (которая находится не в архиве), при которой текущая дата находится между датой начала и датой окончания акции
      .info-row
        ui-tag.fit-height(size="small" name="Актуально" type="default")
        span Актуальная акция - обозначает, что когда наступит дата начала акции, она запустится автоматически
      .info-row
        ui-tag.fit-height(size="small" name="Архив" type="info")
        span Архивная акция
    .info-message Обратите внимание, что если 2 акции запущены одновременно, то на главном экране будет отображена только одна из них. Пожалуйста, следите за тем, чтобы 2 акции не были запущены одновременно.

  settings-panel
    template(#header) {{ pageTitle }}
    template(#headerActions v-if="!showEditPage")
      .link(@click="createPromo") Добавить новую акцию

    .promo-table(v-show="!showEditPage")
      ui-table(
        :data="data"
        :loader="isFetching"
        :columns="columns"
        :has-error="error"
        :header-sticky="true"
        :show-clear="hasFiltersChanges"
        v-model="preFiltrationForm"
        @apply="jumpToPage(1)"
        @clear="clearFilters"
      )
        template(#dateStart="{ rowData }") {{ rowData?.dateStart ? formatDateTime(rowData?.dateStart) : 'Нет данных' }}
        template(#dateFinish="{ rowData }") {{ rowData?.dateFinish ? formatDateTime(rowData?.dateFinish) : 'Нет данных' }}
        template(#serviceTitle="{ rowData }") {{ rowData.serviceTitle }}
        template(#isActual="{ rowData }")
          ui-tag(v-if="rowData.current" size="small" name="Активно" type="success")
          ui-tag(v-else size="small" :name="rowData.isActual ? 'Актуально' : 'Архив'" :type="rowData.isActual ? 'default' : 'info'")
        template(#clearIcon="{ rowData }")
          .flex-row
            icon-button(
              :size="16"
              :class="rowData.isActual && 'icon-red'"
              :icon="rowData.isActual ? UiIconNames.Icon_Unlock : UiIconNames.Icon_Lock"
              :tooltip-title="rowData.isActual ? 'Архивировать' : 'Разархивировать'"
              @click="editPromoStatus(rowData)"
            )
            icon-button(
              :size="16"
              :icon="UiIconNames.Icon_Next"
              tooltip-title="Редактировать акцию"
              @click="editPromo(rowData)"
            )

      ui-pagination(
        :count-results="data.length"
        :total-results="totalResults"
        :current-page="preFiltrationForm.page"
        :total="totalPages"
        @update:current-page="jumpToPage($event, false)"
      )

    .promo-editor(v-if="showEditPage")
      go-back.go-back(bold :icon="UiIconNames.Icon_Back" @click="goBack") Вернуться назад
      promo-editor(:promo-id="idQuery" :is-create="isCreateMode" @success="updatePromoList")

promo-status-dialog(
  v-if="showBlockDialog"
  v-model:show="showBlockDialog"
  :promo="blockPromo"
  :mode="blockPromo?.isActual ? 'lock' : 'unlock'"
  @success="updatePromoList"
)
image-viewer
</template>

<script lang="ts">
import { computed, onBeforeUnmount, defineComponent, ref } from 'vue';
import { useApi } from "~/use/api/useApi";
import { formatDateTime } from "~/utils/formatter/dateFormatter";
import { useRouteQuery } from "@vueuse/router";
import { useRoute, useRouter } from "vue-router";

import SettingsPanel from "~/components/pages/settings/common/SettingsPanel.vue";
import UiTable from "~/components/ui/table/UiTable.vue";
import UiPagination from "~/components/ui/pagination/UiPagination.vue";
import IconButton from "~/components/ui/button/IconButton.vue";
import PromoEditor from "~/components/pages/admin/promo/PromoEditor.vue";
import UiButton from "~/components/ui/button/UiButton.vue";
import MiniLoader from "~/components/ui/loader/MiniLoader.vue";
import GoBack from "~/components/other/GoBack.vue";
import UiTag from "~/components/ui/tag/UiTag.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";
import PromoStatusDialog from "~/components/pages/admin/promo/PromoStatusDialog.vue";
import ImageViewer from "~/components/pages/training/ImageViewer.vue";
import PromoActualFilter from "~/components/filters/promo/PromoActualFilter.vue";
import UiLoader from "~/components/ui/loader/UiLoader.vue";

import { type PromoI, usePromo } from "~/use/other/usePromo";
import { type PreFiltrationFormPromosI, getDefaultPreFiltrationFormPromos } from "~/utils/getters/defaultFilters";
import UiIconNames from "~/components/ui/icon/UiIconNames";
import isEqual from "lodash/isEqual";

export default defineComponent({
  name: "PromoList",
  components: {
    UiLoader,
    ImageViewer,
    PromoStatusDialog,
    AlertMessage,
    UiTag,
    GoBack,
    MiniLoader,
    UiButton,
    PromoEditor,
    IconButton,
    UiTable,
    UiPagination,
    SettingsPanel,
    PromoActualFilter,
  },
  methods: {
    formatDateTime,
  },
  setup() {

    const {
      getPromoPeriod,
      getActualPromoDetails,
    } = usePromo();

    const route = useRoute()
    const router = useRouter();

    const data = ref<PromoI[]>([]);
    const error = ref<boolean>(false);

    const isFetching = ref<boolean>(true);

    const totalPages = ref<number>(1);
    const totalResults = ref<number>(0);

    const blockPromo = ref<PromoI|undefined>();
    const showBlockDialog = ref<boolean>(false);

    const preFiltrationForm = ref<PreFiltrationFormPromosI>(getDefaultPreFiltrationFormPromos());

    const idQuery = useRouteQuery<number | undefined>('id', undefined, { mode: 'push', transform: Number });
    const modeQuery = useRouteQuery<string | undefined>('mode', undefined, { mode: 'push' });

    function getActualFilterValue() {
      if (preFiltrationForm.value.isActual === undefined) return null
      return preFiltrationForm.value.isActual ? 'Актуальные акции' : 'Архивные акции'
    }

    function promoRouteResolve() {
      if (route.query?.mode === 'create') return
      if (route.query?.mode === 'edit' && route.query?.id) return
      if (!route.query?.mode && !route.query?.id) return
      console.log('need replace')
      router.replace({ query: { tab: route.query?.tab }})
    }

    watch(route, promoRouteResolve)
    promoRouteResolve()

    const showEditPage = computed(() => !!modeQuery.value)
    const isCreateMode = computed(() => modeQuery.value === 'create')
    const hasFiltersChanges = computed(() => !isEqual(preFiltrationForm.value, getDefaultPreFiltrationFormPromos()));

    const columns = computed(() => ({
      dateStart: { size: 156, isSort: true, headerLabel: 'Дата начала' },
      dateFinish: { size: 156, headerLabel: 'Дата окончания' },
      serviceTitle: { size: 550, bold: true, headerLabel: 'Название акции' },
      isActual: { size: 140, headerLabel: 'Статус', filterComponent: PromoActualFilter, value: getActualFilterValue() },
      clearIcon: { size: 72 },
    }))

    const pageTitle = computed(() => {
      if (showEditPage.value) return isCreateMode.value ? 'Создание акции' : 'Редактирование акции'
      return 'Список акций'
    })

    const showPromoWarning = computed(() => data.value.filter(e => e.current).length > 1)

    /** получение списка периодизаций акций */
    getPromoPeriod()

    /** получение списка актуальных промо-акций перед уходом со страницы */
    onBeforeUnmount(getActualPromoDetails)

    /** получение списка всех акций */
    useApi().promo.fetchPromoList<PromoI[]>(preFiltrationForm.value)
      .then((hydraData: any) => {
        totalResults.value = Number(hydraData['totalItems'])
        totalPages.value = Math.ceil(hydraData['totalItems'] === 0 ? 1 : (hydraData['totalItems'] / 30))
        data.value = hydraData['member']
      })
      .catch(() => error.value = true)
      .finally(() => isFetching.value = false)

    function jumpToPage(p: number, resetPages = true) {
      preFiltrationForm.value.page = p
      applyFilters(resetPages)
    }

    /** фильтрация акций */
    function applyFilters(resetPages: boolean) {
      isFetching.value = true;

      if (resetPages) {
        totalResults.value = 0
        totalPages.value = 1
      }

      data.value = []
      error.value = false

      useApi().promo.fetchPromoList<PromoI[]>(preFiltrationForm.value)
        .then((filtersData) => {
          totalResults.value = Number(filtersData['totalItems'])
          totalPages.value = Math.ceil(filtersData['totalItems'] === 0 ? 1 : (filtersData['totalItems'] / 30))
          data.value = filtersData['member']
        })
        .catch(() => error.value = true)
        .finally(() => isFetching.value = false)
    }

    /** переход к созданию акции */
    function createPromo() {
      modeQuery.value = 'create'
    }

    /** переход к редактированию акции */
    function editPromo(p: PromoI) {
      if (!p.id) return;

      idQuery.value = p.id
      modeQuery.value = 'edit'
    }

    /** выход из редактирования акции */
    function goBack() {
      modeQuery.value = undefined;
      idQuery.value = undefined;
    }

    function updatePromoList(p: PromoI) {
      const index = data.value.findIndex(e => e.id === p.id)

      if (index !== -1) data.value[index] = p
      else data.value.unshift(p)

      goBack()
    }

    function editPromoStatus(p: PromoI) {
      blockPromo.value = p;
      showBlockDialog.value = true;
    }

    function clearFilters() {
      preFiltrationForm.value = getDefaultPreFiltrationFormPromos();
      jumpToPage(1)
    }

    return {
      data,
      error,
      isFetching,
      totalPages,
      totalResults,
      preFiltrationForm,
      isCreateMode,
      pageTitle,
      columns,
      showBlockDialog,
      blockPromo,
      hasFiltersChanges,
      showPromoWarning,
      showEditPage,
      idQuery,
      editPromoStatus,
      clearFilters,
      goBack,
      editPromo,
      createPromo,
      jumpToPage,
      applyFilters,
      updatePromoList,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/links";

.link {
  @include link-mixin;

  &:hover {
    color: var(--main-color-blue-dark);
  }
}

.promo-list {
  display: flex;
  flex-flow: column;
  gap: 24px;
}

.promo-table,
.promo-editor {
  display: flex;
  flex-flow: column;
  gap: 24px;

  :deep(.icon-red) {
    color: var(--main-red-color) !important;
  }
}

.go-back {
  cursor: pointer;
  color: var(--default-blue-color) !important;
}

.loader {
  align-self: center;
}

.flex-row {
  display: flex;
  flex-flow: row;
  gap: 0;
}

.info-panel {
  font-size: 13px;

  .info__status {
    display: flex;
    flex-flow: column;
    gap: 12px;
  }

  .info-row {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 24px;
  }

  .fit-height {
    height: fit-content;
  }
}
</style>
