<template lang="pug">
  filter-template.promo-filter(
    @reset="cleanFilter"
    @save="applyFilters"
    @cancel="cancel"
  )
    template(#header) Статус акции
    template(#content)
      radio-group(v-model="filters.isActual" :options="options" id="promo-actual-filter")
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { PreFiltrationFormClientsI } from "~/utils/getters/defaultFilters";

import FilterTemplate from "~/components/filters/FilterTemplate.vue";
import RadioGroup from "~/components/ui/radio/RadioGroup.vue";

import { defineComponent, toRefs } from 'vue'
import { getDefaultPreFiltrationFormClients } from "~/utils/getters/defaultFilters";
import { useFiltersComponent } from "~/use/filters/useFiltersComponent";

export default defineComponent({
  name: "PromoActualFilter",
  components: {
    RadioGroup,
    FilterTemplate,
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormClientsI>,
      default: getDefaultPreFiltrationFormClients(),
    },
  },
  setup(props, { emit }) {

    const { modelValue } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent(modelValue, false, ['isActual'], emit, 'promos')

    const options = [
      { id: undefined, title: 'Все акции' },
      { id: true, title: 'Только актуальные акции' },
      { id: false, title: 'Только архивные акции' },
    ]

    return {
      options,
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    }
  }
})
</script>

<style scoped lang="scss">
.promo-filter {
  width: 320px;
}
</style>
